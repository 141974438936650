export const environment = {
    production: false,
    API_SERVER_ADDRESS: 'https://uapi.clouddoctor.asia/uapi/asset/',
    API_SERVER_ADDRESS2: 'https://uapi.clouddoctor.asia/uapi/',
    GenPdf: 'https://kodocgen.rksoft.me/assets-docgen/api/assets/',
    LiffId: '1656066721-bGvAqZyA',
    LiffId2: '1656066721-LwRXey8X',
    DEFAULT_LANGUAGE: 'th',
    VideoUserType: 2,
    JANUS_SERVER: 'wss://vdocloud.clouddoctor.asia/ws',
    //JANUS_SERVER: 'wss://janus.rksoft.me/ws',
    customerMode: true
};
