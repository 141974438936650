import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Cloud Doctor Client : โรงพยาบาลเสมือนจริงเพื่อคนไข้ในพื้นที่ห่างไกล';

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }
}
