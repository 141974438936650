import {Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoaderComponent} from './shared/loading/loader/loader.component';
import {LoaderService} from './shared/loading/loader.service';
import {LoaderInterceptorService} from './shared/loading/loader-interceptor.service';

@Injectable()
export class ParameterHashLocationStrategy extends HashLocationStrategy {
    prepareExternalUrl(internal: string): string {
        console.log('preparing external url', window.location.search, super.prepareExternalUrl(internal));
        return window.location.search + super.prepareExternalUrl(internal);
    }
}

@NgModule({
    declarations: [AppComponent, LoaderComponent],
    imports: [BrowserAnimationsModule, BrowserModule, AppRoutingModule, NgxSpinnerModule, NgbModule, HttpClientModule],
    providers: [
        /*
        {
            provide: LocationStrategy,
            useClass: ParameterHashLocationStrategy
        }, // */
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
