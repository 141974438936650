import {Component, OnInit} from '@angular/core';
// @ts-ignore
import {NgxSpinnerService} from 'ngx-spinner';
import {LoaderService} from '../loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
    isLoading$ = this.loaderService.isLoading;
    isGlobalLoading$ = this.loaderService.isGlobalLoading;
    isLoading: boolean = false;
    isGlobalLoading: boolean = false;

    constructor(private loaderService: LoaderService, private spinner: NgxSpinnerService) {
        this.spinner.show();
    }

    ngOnInit(): void {
        this.isLoading$.subscribe(x => {
            this.isLoading = x;
        });

        this.isGlobalLoading$.subscribe(x => {
            this.isGlobalLoading = x;
        });
    }
}
