import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Logger} from '../logger.service';

const logger = new Logger('Loader Service');

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading = new BehaviorSubject(false);
    isGlobalLoading = new BehaviorSubject(false);

    constructor() {}

    show() {
        this.isLoading.next(true);
    }

    hide() {
        this.isLoading.next(false);
    }
}
